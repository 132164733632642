import Vue from "vue"
import Router from "vue-router"

//Token Check
import Auth from "@/services/Auth"

// Containers
const TheContainer = () =>
    import ("@/containers/TheContainer")
    // Views

const PageNopermission = () =>
    import ("@/views/pages/PageNoPermission")

const Page404 = () =>
    import ("@/views/pages/Page404")

const Page500 = () =>
    import ("@/views/pages/Page500")

const Login = () =>
    import ("@/views/pages/Login")

const Register = () =>
    import ("@/views/pages/Register")

// Auth
const Callback = () =>
    import ("@/views/auth/Callback")
const Signout = () =>
    import ("@/views/auth/SignOut")
const Signin = () =>
    import ("@/views/auth/SignIn")

// ! Recording Page 
const AllTaskOfRecording = () =>
    import ("@/views/recording/recordingTablePage")
const ErrorPage = () =>
    import ("@/views/recording/errorPage")
const RecordingOfTeacher = () =>
    import ("@/views/recording/teacherPage")

Vue.use(Router)

const router = new Router({
    mode: "history", // https://router.vuejs.org/api/#mode
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
})

function configRoutes() {
    return [{
            path: "/",
            redirect: "/error",
            name: "Home",
            component: TheContainer,
            children: [{
                    path: "error",
                    name: "Error Dashboard Page",
                    component: ErrorPage,
                },
                {
                    path: "recording",
                    redirect: "/recording/all",
                    name: "Recording",
                    component: {
                        render(c) {
                            return c("router-view")
                        },
                    },
                    children: [{
                            path: "all",
                            name: "All recording",
                            component: AllTaskOfRecording,
                        },
                        {
                            path: "user",
                            name: "Recording of Teacher",
                            component: RecordingOfTeacher,
                        },
                    ],
                },
            ],
        },
        {
            path: "/pages",
            redirect: "/pages/404",
            name: "Pages",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                    path: "nopermission",
                    name: "Nopermission",
                    component: PageNopermission,
                },
                {
                    path: "404",
                    name: "Page404",
                    component: Page404,
                },
                {
                    path: "500",
                    name: "Page500",
                    component: Page500,
                },
                {
                    path: "login",
                    name: "Login",
                    component: Login,
                },
                {
                    path: "register",
                    name: "Register",
                    component: Register,
                },
            ],
        },
        {
            path: "/callback",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Callback",
                component: Callback,
            }, ],
        },
        {
            path: "/signout",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Signout",
                component: Signout,
            }, ],
        },
        {
            path: "/signin",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Signin",
                component: Signin,
            }, ],
        },
        {
            path: "*",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "404",
                component: Page404,
            }, ],
        },
    ]
}
router.beforeEach((to, from, next) => {
    let Acctype = localStorage.getItem("accType")
    let token = localStorage.getItem("access_token")
    if (token !== null) {
        Auth.getUserInGraph(token).then((user) => {
            if (user instanceof Error) {
                localStorage.removeItem("access_token")
                localStorage.removeItem("email")
                localStorage.removeItem("name")
                localStorage.removeItem("accType")
                localStorage.removeItem("code")
                localStorage.removeItem("userProfile")
                localStorage.removeItem("Admin")
                localStorage.setItem("beforepath", to.fullPath)
                next({ name: "Signin" })
            }
        })
    }
    if (to.name !== "Signin" && to.name !== "Callback" && to.name !== "Page500" && token === null) {
        localStorage.setItem("beforepath", to.fullPath)
        next({ name: "Signin" })
    } else if (to.name === "Dashboard" || to.name === "EventList" || to.name === "EventDetail") {
        if (Acctype !== "Employee") {
            next({ name: "Nopermission" })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router