import "core-js/stable"
import Vue from "vue"
import App from "./App"
import router from "./router"
import CoreuiVue from "@coreui/vue"
import { iconsSet as icons } from "./assets/icons/icons.js"
import store from "./store"

import VueAxios from "vue-axios"
import axios from "axios"

import { BootstrapVue, IconsPlugin } from "bootstrap-vue"

import babelPolyfill from "babel-polyfill"

import Vuetify from "vuetify"
import VCalendar from "v-calendar"

import VueToast from "vue-toast-notification"
import "vue-toast-notification/dist/theme-sugar.css"

import _ from "lodash"

import VueClipboard from "vue-clipboard2"

import VueFab from "vue-float-action-button"

import VueMaterial from "vue-material"

import * as VueGoogleMaps from "vue2-google-maps"

import { LMap, LTileLayer, LMarker } from "vue2-leaflet"
import "leaflet/dist/leaflet.css"
import { Icon } from "leaflet"
Vue.component("l-map", LMap)
Vue.component("l-tile-layer", LTileLayer)
Vue.component("l-marker", LMarker)
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})
Vue.use(_)

Vue.use(VueToast)

Vue.use(VueClipboard)

Vue.use(VueFab)

Vue.use(VueGoogleMaps, {
        load: {
            key: "AIzaSyBtN0FixGRpjAYMdjM7KHzr3DZPipdpaFA",
            libraries: "places",
        },
    })
    // import Swal from 'sweetalert2'

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css"
// import 'bootstrap-css-only/css/bootstrap.min.css'
// import 'mdbvue/lib/css/mdb.min.css'
// import '@fortawesome/fontawesome-free/css/all.min.css'

// import 'vuetify/dist/vuetify.min.css'

// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Vuetify)

Vue.use(VueMaterial)

// Vue.use(Swal)
Vue.use(VCalendar, {
    componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
})

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.use(VueAxios, axios)

// let vuetify = new Vuetify({ icons: { iconfont: 'mdi' }, theme: theme })

new Vue({
    el: "#app",
    vuetify: new Vuetify(),
    router,
    store,
    icons,
    template: "<App/>",
    components: {
        App,
    },
})