import axios from "axios"
const basicAuth = "Basic " + btoa(process.env.VUE_APP_EXAM_API_USER + ":" + process.env.VUE_APP_EXAM_API_PASSWORD)
const basicAuthSzrApi = "Basic " + btoa(process.env.VUE_APP_SYNC_API_AUTH_USERNAME + ":" + process.env.VUE_APP_SYNC_API_AUTH_PASSWORD)

export const szrApiV2 = axios.create({
    baseURL: process.env.VUE_APP_SYNC_API_URL,
    headers: {
        Authorization: basicAuthSzrApi,
    },
})

export const AUTH = axios.create({
    baseURL: process.env.VUE_APP_AUTH_URL,
    headers: {
        Authorization: basicAuth,
    },
})